/* Riot v9.1.2, @license MIT */
import { MOUNT_METHOD_KEY, UPDATE_METHOD_KEY, UNMOUNT_METHOD_KEY } from '../dependencies/@riotjs/util/constants.js';
import { removeChild, cleanNode } from '../dependencies/@riotjs/util/dom.js';
import { noop } from '../dependencies/@riotjs/util/functions.js';

// Components without template use a mocked template interface with some basic functionalities to
// guarantee consistent rendering behaviour see https://github.com/riot/riot/issues/2984
const MOCKED_TEMPLATE_INTERFACE = {
  [MOUNT_METHOD_KEY](el) {
    this.el = el;
  },
  [UPDATE_METHOD_KEY]: noop,
  [UNMOUNT_METHOD_KEY](_, __, mustRemoveRoot = false) {
    if (mustRemoveRoot) removeChild(this.el);
    else if (!mustRemoveRoot) cleanNode(this.el);
  },
  clone: noop,
  createDOM: noop,
};

export { MOCKED_TEMPLATE_INTERFACE };
