import { component } from "riot"
import MainSite from "./tags/main-site.riot"

// Mount app once the DOM is ready
const $ = document

$.addEventListener("DOMContentLoaded", () => {
	const el = $.querySelector("main-site")
	component(MainSite)(el)
	el.removeChild($.querySelector(".no-javascript"))
})

window.addEventListener("load", async () => {
	function preloadImage(src) {
		var el = $.createElement("img")
		el.setAttribute("class", "preloadImage")
		el.setAttribute("src", src)
		$.body.appendChild(el)
		return new Promise((resolve, reject) => {
			el.onload = () => {
				if (el.parentNode) {
					el.parentNode.removeChild(el)
				}
				resolve()
			}
			el.onerror = reject
		})
	}

	try {
		// Preload all images in keyframes CSS rules before starting slideshow
		var imageSet = new Set()
		for (var i = 0; i < $.styleSheets.length; i++) {
			// Skip cross-origin stylesheets
			if (!$.styleSheets[i].href.includes(location.hostname)) {
				continue
			}
			var rules = $.styleSheets[i].cssRules
			for (var j = 0; j < rules.length; j++) {
				if (
					rules[j].type === CSSRule.KEYFRAMES_RULE &&
					rules[j].name === "slideshow"
				) {
					for (var k = 0; k < rules[j].cssRules.length; k++) {
						var match = (
							rules[j].cssRules[k].style["background-image"] || ""
						).match(/^url\((['"]?)(.*)\1\)$/)
						if (match) {
							imageSet.add(match[2])
						}
					}
				}
			}
		}
		await Promise.all(Array.from(imageSet).map(preloadImage))
	} catch (e) {
		console.error(e)
	}
	// Start slideshow and kenburns animation
	var img = $.getElementById("slideshowImage")
	img.style.animationPlayState = "running"
	img.style.animationDelay = "-0.65s" // skip fade-in
})
