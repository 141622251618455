main-site
	#home
	header(is="header", fixed="fixed")
	.splash
		header(is="header")
		.center
			h1 Custom Software
				br
				| For Your Business
			p.
				iCompute is a small, nimble team of software engineers and
				designers who love building high-end software for businesses
				right here in Northeast Ohio. We combine cutting-edge technologies
				and years of expertise to produce software that will last decades.
			p.
				Got ideas for your business? Contact us today for a free consultation!
			.buttons
				a(href="#services") Learn More
				a(href="#contact-us") Contact Us
		.image-container
			#slideshowImage.image
			.pixel-overlay
	section#about-us.fold
		.content
			h1= "About "
				span.logo
					span.italic iCOMPUTE
			p.
				iCompute is a small team of software engineers and designers who build software
				solutions for local Ohio businesses. We work closely with you, our client,
				to determine your needs, and then we design software that you will love. All
				of our work is proudly made in Northeast Ohio!
			.team
				.person
					img(src="{ images.blake }", alt="Blake Miner")
					.name Blake Miner
					.title Founder - Software Developer
					.description
						| Blake has worked as a software developer for over ten years and specializes
						| in web application development using
						= " "
						a(href="https://nodejs.org/", rel="noreferrer", target="_blank") Node.js
						| , a tool for building scalable and robust web applications. His
						| professional experience ranges from cryptography to server
						| administration, from database design to analytics.
						| Blake contributes to various
						|
						a(href="#open-source", onclick="{scrollAbove}") open-source projects
						|
						| and also maintains a few
						|
						a(href="https://github.com/bminer", rel="noreferrer", target="_blank") projects of his own
						|
						| on Github.
					.icons(
						onmouseover="{ setHoveredIcon }",
						onmouseout="{ clearHoveredIcon }"
					)
						a.github(
							href="https://github.com/bminer",
							title="Github",
							rel="noreferrer",
							target="_blank"
						)
						a.linked-in(
							href="https://www.linkedin.com/in/blakecminer/",
							title="LinkedIn Profile",
							rel="noreferrer",
							target="_blank"
						)
					.hovered {state.hoveredIcon}&nbsp;
	section#services.fold
		.content
			h1 Services
			p The things that we love to do:
			.list
				service(
					title="Application Development",
					service="{ state.service }",
					setService="{ setService }"
				)
					.glyph &#xf121
				service(
					title="Server Administration",
					service="{ state.service }",
					setService="{ setService }"
				)
					.glyph &#xf0ee
				service(
					title="Web Design",
					service="{ state.service }",
					setService="{ setService }"
				)
					.glyph &#xf1fc
				service(
					title="Software Consulting",
					service="{ state.service }",
					setService="{ setService }"
				)
					.glyph &#xf086
				service(
					title="Technical Support",
					service="{ state.service }",
					setService="{ setService }"
				)
					.glyph &#xf059
			.center.italic (Psst: Click on a service above to learn more.)
			.descriptions
				h3 {state.service}
				div(class="{ state.service === 'Application Development' ? '' : 'hide' }")
					p.
						Our expert developers can build solutions from scratch or add on to
						your existing software infrastructure. We also utilize a variety
						of cutting-edge technologies including:
					ul
						li
							a(href="https://nodejs.org/", rel="noreferrer", target="_blank") Node.js
							|
							| or
							= " "
							a(href="https://go.dev/", rel="noreferrer", target="_blank") Golang
							|
							| for back-end web services and web applications
						li
							a(href="https://riot.js.org", rel="noreferrer", target="_blank") Riot.js
							|
							| or
							= " "
							a(href="https://reactjs.org/", rel="noreferrer", target="_blank") React
							|
							| web front-end frameworks
						li
							a(href="https://www.mysql.com", rel="noreferrer", target="_blank") MySQL
							|
							| and/or
							= " "
							a(href="https://redis.io", rel="noreferrer", target="_blank") Redis
							|
							| for database storage
						li C/C++ for firmware or low-level software development
						li ... and many others
					p.
						In our experience, the software libraries and technology stack
						drastically affects the ability for software to evolve over
						time.  We know how to navigate these technical waters to keep your
						project within budget while minimizing the total "cost of ownership"
						for years to come.
					img(src="{ images.code }", alt="Code", height=150)
				div(class="{ state.service == 'Server Administration' ? '' : 'hide' }")
					p
						| Our team has years of server experience. While we are most comfortable
						| with
						= " "
						a(href="https://aws.amazon.com", rel="noreferrer", target="_blank") Amazon Web Services
						|
						| and Debian flavors of Linux, we can help you
						| choose any environment for running your new, polished code.
					p
						| We also offer hosting services and 24/7 support. Please feel free to
						= " "
						a(href="#contact-us") contact us
						|
						| if you are interested!
				div(class="{ state.service == 'Web Design' ? '' : 'hide' }")
					p.
						A good design is an important part of building high-end software.
						At the onset of a new project, we work carefully with you to understand
						the people who will use the software, analyze which features are most
						important to them, and incorporate this research into the design.
					img(src="{ images.sketch }", alt="Sketch", height=200)
				div(class="{ state.service == 'Software Consulting' ? '' : 'hide' }")
					p.
						Building great software isn't just about programming; it's about
						guidance and planning, too.  Our ability to translate your needs into
						software requirements and translate technical details into English is
						what sets us apart from other software firms.
					p
						a(href="#contact-us") Contact us
						|
						| today for a free consultation, and learn more about how we can help
						| you with your software needs.
				div(class="{ state.service == 'Technical Support' ? '' : 'hide' }")
					p.
						We proudly stand behind our work, and we offer on-going technical
						support if you need it. Need small changes made to your app every
						month? Need 24/7 support just in case someone breaks something?
						At iCompute, we have you covered!
					img(src="{ images.uptime }", alt="Server Uptime", height=200)
	section#portfolio.fold
		.content
			h1 Success Stories
			.project
				h3
					a(href="https://powertoolrepair.com", rel="noreferrer", target="_blank") Power Tool Repair
				.slideshow
					button(onclick="{ scroll(-300) }") «
					.figures
						figure(each="{ data in images.portfolio.ptr }")
							img(src="{ data.src }", alt="{ data.alt }", height="450")
							figcaption {data.alt}
					button(onclick="{ scroll(300) }") »
				h4 What we built:
				ul
					li Order Fulfillment
					li Inventory Management
					li Shipping & Invoicing
					li Purchasing
					li Reporting
					li E-Commerce Website
				h4 Building blocks:
				ul
					li
						a(href="https://nodejs.org/en/", rel="noreferrer", target="_blank") Node.js
						= " - "
						a(href="https://koajs.com/", rel="noreferrer", target="_blank") Koa web framework
						= "; "
						a(href="https://sequelize.org/", rel="noreferrer", target="_blank") Sequelize ORM
						= " with "
						a(href="https://mysql.com/", rel="noreferrer", target="_blank") MySQL database
					li
						a(href="https://reactjs.org/", rel="noreferrer", target="_blank") React.js
						= " for primary application and "
						a(href="https://nextjs.org", rel="noreferrer", target="_blank") Next.js
						|
						| for e-commerce website
					li
						a(href="https://www.easypost.com/", rel="noreferrer", target="_blank") EasyPost
						|
						| integration for shipping & receiving
					li
						a(href="https://www.stripe.com/", rel="noreferrer", target="_blank") Stripe
						|
						| integration for payment processing
					li
						a(href="https://mailchimp.com/", rel="noreferrer", target="_blank") Mailchimp
						|
						| integration for email marketing
					li
						a(href="https://directus.io", rel="noreferrer", target="_blank") Directus CMS
			.project
				h3
					a(href="https://sweetamandas.com", rel="noreferrer", target="_blank") Sweet Amanda's
				.slideshow
					button(onclick="{ scroll(-300) }") «
					.figures
						figure(each="{ data in images.portfolio.sa }")
							img(src="{ data.src }", alt="{ data.alt }", height="450")
							figcaption {data.alt}
					button(onclick="{ scroll(300) }") »
				h4 What we built:
				ul
					li Vending Machine User Interface (
						a(
							href="https://www.youtube.com/watch?v=lUjdt_DhjQQ",
							rel="noreferrer",
							target="_blank"
						) watch the product video
						| )
					li Firmware for Controller PCBAs
					li Vending Machine Management
					li Product Inventory and Shipping
					li Technician and Staff Management
					li Reporting
					li Company Website
				h4 Building blocks:
				ul
					li Debian Linux with Chromium running on kiosk
					li
						a(href="https://nodejs.org/en/", rel="noreferrer", target="_blank") Node.js
						= " - "
						a(href="https://koajs.com/", rel="noreferrer", target="_blank") Koa web framework
						= "; "
						a(href="https://mysql.com/", rel="noreferrer", target="_blank") MySQL
						= " and "
						a(href="https://redis.io/", rel="noreferrer", target="_blank") Redis
					li
						a(href="https://riot.js.org", rel="noreferrer", target="_blank") Riot.js
						|
						| for kiosk user interface and remote kiosk management web application
					li WebSocket API using
						= " "
						a(
							href="https://github.com/bminer/ws-wrapper",
							rel="noreferrer",
							target="_blank"
						) ws-wrapper
			.project
				h3#open-source Open Source
					.small (actively maintained)
				ul
					li
						a(href="https://docs.simpleiot.org/"
							rel="noreferrer",
							target="_blank"
						) Simple IoT
						|
						| - Enables you to add remote sensor data, telemetry, configuration, and device management to your project or product
					li
						a(
							href="https://github.com/bminer/ws-wrapper",
							rel="noreferrer",
							target="_blank"
						) ws-wrapper
						|
						| - Lightweight WebSocket lib with socket.io-like event handling, requests, and channels
					li
						a(
							href="https://github.com/bminer/ws-server-wrapper",
							rel="noreferrer",
							target="_blank"
						) ws-server-wrapper
						|
						| - Lightweight WebSocketServer wrapper lib using ws-wrapper to wrap connected WebSockets
					li
						a(
							href="https://github.com/bminer/schemer",
							rel="noreferrer",
							target="_blank"
						) schemer
						|
						| - Lightweight and robust data encoding library for Go
					li
						a(
							href="https://github.com/bminer/module-concat",
							rel="noreferrer",
							target="_blank"
						) module-concat
						|
						| - Fairly lightweight CommonJS module concatenation tool
	section#our-process.fold
		.content
			h1 Our Process
			p.
				When evaluating a software partner, it's important to pick a company that
				intimately understands your needs and knows how to manage projects and
				resources.  Many agencies can write "code", but few know how to build
				large-scale, high-end software to suit your ever-changing business needs.
			p
				| At iCompute, we will guide you through our
				= " "
				span.italic proven
				|
				| software development process:
			.timeline
				.line
				.step
					h3 Plan
					p
						img(src="{ images.idea }", alt="Idea", height=70)
						| We start with understanding your business problem(s) and regularly meet
						| throughout the software development process to carefully review your goals
						| and requirements.
				.step
					h3 Design
					p
						img(src="{ images.sketch }", alt="Sketch", height=70)
						| Custom software should look and feel great, not just simply work.
						| That's why our designs are focused on
						= " "
						span.italic usability
						|
						| and
						= " "
						span.italic style
						| . Our goal is to build software that is beautiful, efficient, and easy to use.
				.step
					h3 Build
					p
						img(src="{ images.gearsAnimation }", alt="Gears", height=70)
						| Building software is a craft. Our experienced team builds systems that
						| stand the test of time, integrate seamlessly into current business
						| processes and add value to your business.
				.step
					h3 Deploy
					p
						img(src="{ images.rocket }", alt="Rocket", height=70)
						| We work with you to outline a deployment plan, provide hosting
						| services if needed, and develop documentation and/or training materials.
				.step
					h3 Evolve
					p
						img(src="{ images.evolve }", alt="Evolve", height=70)
						| Software development is a circular process that never truly ends. At
						| iCompute, we understand that business requirements regularly change,
						| and we ensure that your software is robust enough to evolve with your
						| business.
					p We always stand by our software and provide ongoing support if needed.
	section#quotes
		.content
			blockquote
				.quote.
					Our custom software solutions enable you to capture all of your
					essential data in one place, leading to improved productivity and
					faster, more intelligent decision-making. As a result, your
					company can realize substantial cost savings and greater
					scalability.
				.quotee
					| Blake Miner, Founder & Software Developer
					img(src="{ images.blake }", alt="Blake Miner")
	section#contact-us
		.content
			h1 Contact Us
			p It's time to launch software that you'll love. Contact us for a free consultation!
			.grid
				form(method="post", action="/contact", onsubmit="{ submitContact }")
					input(
						type="hidden",
						name="preventSpam",
						value="{ atob('Tm8sIHRoYW5rIHlvdSE=') }"
					)
					label
						span.text Name:
						input(
							name="name",
							type="text",
							placeholder="Enter your full name",
							required
						)
					label
						span.text Email:
						input(
							name="email",
							type="email",
							placeholder="Enter your email address",
							required
						)
					label
						span.text Phone:
						input(
							name="phone",
							type="tel",
							placeholder="Enter your phone # (optional)"
						)
					label
						span.text Message:
						textarea(
							name="message",
							placeholder="Type us a message. Maybe tell us about your company or a project that's on your mind?",
							rows=6
						)
					button Send Message
				p.message(if="{ state.contactMessage }", class="{ state.contactClass }") {state.contactMessage}
				.contact
					//- Base 64 encoded email to "trick" dumb bots: info@icomputeconsulting.com
					a.email(
						href="{ atob('bWFpbHRvOmluZm9AaWNvbXB1dGVjb25zdWx0aW5nLmNvbQ==') }"
					)
						| {atob("aW5mb0BpY29tcHV0ZWNvbnN1bHRpbmcuY29t")}
					br
						//- Base 64 encoded phone to "trick" dumb bots: +1-330-212-7424
					a.phone(href="{ atob('dGVsOisxLTMzMC0yMTItNzQyNA==') }")
						| {atob("KzEtMzMwLTIxMi03NDI0")}
					hr
					.address 912 Martindale Dr.
						br
						| Tallmadge, OH 44278
	job-postings
	footer
		.copy &copy; Copyright 2011-{new Date().getFullYear()}. iCompute Consulting, LLC. All rights reserved.
		//- .credits
			span.glyph &#xf2b4&nbsp;
			a(href="http://fontawesome.io/", rel="noreferrer", target="_blank") Font Awesome
			|
			| iconic font by Dave Gandy.
	script.
		// Add tag dependencies
		import Header from "./header.riot"
		import Service from "./service.riot"
		import JobPostings from "./job-postings.riot"

		import blake from "../static/images/team/miner-blake.jpg"
		import idea from "../static/images/idea.png"
		import sketch from "../static/images/sketch.jpeg"
		import gearsAnimation from "url:../static/images/gears-animation.gif"
		import rocket from "../static/images/rocket.png"
		import evolve from "../static/images/evolve.png"
		import code from "../static/images/code.jpg"
		import uptime from "../static/images/uptime.png"

		import ptr1 from "../static/images/portfolio/PTR/E-Commerce Homepage.png"
		import ptr2 from "../static/images/portfolio/PTR/E-Commerce Tool Details.png"
		import ptr3 from "../static/images/portfolio/PTR/Image Capture.png"
		import ptr4 from "../static/images/portfolio/PTR/Invoice Details.png"
		import ptr5 from "../static/images/portfolio/PTR/Line Drawing.png"
		import ptr6 from "../static/images/portfolio/PTR/New Customer.png"
		import ptr7 from "../static/images/portfolio/PTR/Picking.png"
		import ptr8 from "../static/images/portfolio/PTR/Reports.png"
		import ptr9 from "../static/images/portfolio/PTR/Shipping Modal.png"

		import sa1 from "../static/images/portfolio/SA/Kiosk.png"
		import sa2 from "../static/images/portfolio/SA/Customer UI.jpg"
		import sa3 from "../static/images/portfolio/SA/SASSY.png"

		const SUCCESS_MESSAGE = "Thank you for contacting us! We will be in touch with you soon."
		const ERROR_MESSAGE = "How embarassing! There was an error submitting your information. We apologize for the inconvenience! Please try calling or emailing us instead."

		export default {
			state: {
				service: "Application Development",
				scrollLeft: 0,
			},
			atob: window.atob.bind(window),
			images: {
				blake,
				idea,
				sketch,
				gearsAnimation,
				rocket,
				evolve,
				code,
				uptime,
				portfolio: {
					ptr: [
						{
							src: ptr1,
							alt: "E-Commerce Homepage",
						},
						{
							src: ptr2,
							alt: "E-Commerce Tool Details page",
						},
						{
							src: ptr3,
							alt: "Order Fulfillment: Capturing the condition of a tool using a webcam",
						},
						{
							src: ptr4,
							alt: "Invoice Details",
						},
						{
							src: ptr5,
							alt: "Order Fulfillment system: picking replacement parts for a tool",
						},
						{
							src: ptr6,
							alt: "Order Fulfillment: Order entry",
						},
						{
							src: ptr7,
							alt: "Inventory Management: Picking parts and adjusting inventory",
						},
						{
							src: ptr8,
							alt: "Customzied reporting",
						},
						{
							src: ptr9,
							alt: "Shipping and Invoicing",
						},
					],
					sa: [
						{ src: sa1, alt: "Sweet Amanda's Candy Machine" },
						{ src: sa2, alt: "Kiosk's Customer User Interface (vertical orientation)" },
						{ src: sa3, alt: "Remote Kiosk Management System" },
					],
				},
			},
			components: {
				Header,
				Service,
				JobPostings,
			},
			submitContact(e) {
				try {
					this.update({
						contactClass: null,
						contactMessage: "Sending your message... please wait...",
					})
					var form = e.currentTarget,
						body = {}
					// Load FormData into `body` Object
					for (let entry of new FormData(form)) {
						// Note: `entry` is 2-element Array with key and value
						body[entry[0]] = entry[1]
					}
					// Use Fetch API to send JSON request
					fetch(form.action, {
						method: form.method,
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(body),
					})
						.then((res) => {
							if (!res.ok) {
								throw new Error("Invalid response code")
							}
							this.update({
								contactMessage: SUCCESS_MESSAGE,
							})
						})
						.catch((err) => {
							this.update({
								contactClass: "error",
								contactMessage: ERROR_MESSAGE,
							})
						})
					// If `fetch()` did not throw, prevent default behavior
					e.preventDefault()
				} catch (e) {
					// Fallback is to submit form normally
				}
			},
			setService(e) {
				this.update({ service: e.currentTarget.getAttribute("title") })
			},
			setHoveredIcon(e) {
				this.update({ hoveredIcon: e.target.getAttribute("title") })
			},
			clearHoveredIcon(e) {
				this.update({ hoveredIcon: null })
			},
			scroll(amount) {
				return (e) => {
					e.preventDefault()
					const el = e.target[(amount < 0 ? "next" : "previous") + "Sibling"]
					// this.update({ scrollLeft: el.scrollLeft + amount });
					el.scrollLeft += amount
					// $0.parentNode.scrollLeft = $0.offsetLeft - $0.parentNode.offsetLeft
				}
			},
			scrollAbove(e) {
				const target = this.$(e.target.getAttribute("href"))
				if(!target) {
					return
				}
				e.preventDefault()
				window.scrollTo(0, target.offsetTop - this.$("header.fixed").offsetHeight)
			},
		}
