header(class="{ state.classes }")
	a.logo(href="/") &lt;
		span.italic i
		| &gt;
		.small.italic iCOMPUTE CONSULTING
	nav
		a(href="/#home") Home
		a(href="/#about-us") About Us
		a(href="/#services") Services
		a(href="/#portfolio") Portfolio
		a(href="/#our-process") Our Process
		a(href="/#contact-us") Contact
	script.
		import cn from "classnames"

		export default {
			onBeforeMount(props, state) {
				this.state = {
					classes: cn(props.fixed && "fixed"),
				}
			},
			onMounted(props) {
				this.scrollHandler = (e) => {
					// Make fixed header visible when user scrolls below 1/3 of page
					if (window.scrollY >= window.innerHeight / 3) {
						this.update({ classes: cn("fixed", "visible") })
					} else {
						this.update({ classes: "fixed" })
					}
				}
				if (props.fixed) {
					window.addEventListener("scroll", this.scrollHandler)
				}
			},
			onUnmounted() {
				window.removeEventListener("scroll", this.scrollHandler)
			},
		}
