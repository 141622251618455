/* Riot WIP, @license MIT */
// Riot.js constants that can be used across more modules

const COMPONENTS_IMPLEMENTATION_MAP = new Map(),
  DOM_COMPONENT_INSTANCE_PROPERTY = Symbol('riot-component'),
  PLUGINS_SET = new Set(),
  IS_DIRECTIVE = 'is',
  MOUNT_METHOD_KEY = 'mount',
  UPDATE_METHOD_KEY = 'update',
  UNMOUNT_METHOD_KEY = 'unmount',
  SHOULD_UPDATE_KEY = 'shouldUpdate',
  ON_BEFORE_MOUNT_KEY = 'onBeforeMount',
  ON_MOUNTED_KEY = 'onMounted',
  ON_BEFORE_UPDATE_KEY = 'onBeforeUpdate',
  ON_UPDATED_KEY = 'onUpdated',
  ON_BEFORE_UNMOUNT_KEY = 'onBeforeUnmount',
  ON_UNMOUNTED_KEY = 'onUnmounted',
  PROPS_KEY = 'props',
  STATE_KEY = 'state',
  SLOTS_KEY = 'slots',
  ROOT_KEY = 'root',
  IS_PURE_SYMBOL = Symbol('pure'),
  IS_COMPONENT_UPDATING = Symbol('is_updating'),
  PARENT_KEY_SYMBOL = Symbol('parent'),
  ATTRIBUTES_KEY_SYMBOL = Symbol('attributes'),
  TEMPLATE_KEY_SYMBOL = Symbol('template');

export { ATTRIBUTES_KEY_SYMBOL, COMPONENTS_IMPLEMENTATION_MAP, DOM_COMPONENT_INSTANCE_PROPERTY, IS_COMPONENT_UPDATING, IS_DIRECTIVE, IS_PURE_SYMBOL, MOUNT_METHOD_KEY, ON_BEFORE_MOUNT_KEY, ON_BEFORE_UNMOUNT_KEY, ON_BEFORE_UPDATE_KEY, ON_MOUNTED_KEY, ON_UNMOUNTED_KEY, ON_UPDATED_KEY, PARENT_KEY_SYMBOL, PLUGINS_SET, PROPS_KEY, ROOT_KEY, SHOULD_UPDATE_KEY, SLOTS_KEY, STATE_KEY, TEMPLATE_KEY_SYMBOL, UNMOUNT_METHOD_KEY, UPDATE_METHOD_KEY };
