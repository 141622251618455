job-postings(class="{ state.classes }")
	section#job-postings
		.content
			h1 Job Postings
			//-p No positions are open at this time, but you're welcome to contact us if you're really interested in a job.
			.post
				p.right Posted August 7, 2018
				h2 Web Application Developer
				h3 Overview
				p iCompute Consulting is a small start-up firm located in Tallmadge, OH that specializes in designing high-end software for small- to mid-sized businesses. We are currently looking for a front-end or full-stack web developer who can help us design and build web applications using Node.js, Golang, and other modern web technologies.
				p The applicant must be familiar with HTTP, HTML, CSS, and JavaScript. Our ideal candidate is creative; well-versed in front-end web development; eager to learn new technologies; and, able to work independently (as well as with a team). A passion for computer science and/or software development is a huge plus!
				p This will initially be a part-time, contractual (1099) position (approx. 20 hours per week) with the opportunity to grow into a full-time position (40 hours per week) at a later date.
				h3 Compensation
				p Starting at $35 per hour, based on experience.
				h3 Job Responsibilities
				ul
					li Designing, developing, and testing user interfaces for full-stack web applications;
					li Working independently on projects and tasks and regularly communicating progress;
					li Learning about new technologies that can be used for our projects.
				h3 Qualifications
				ul
					li A Bachelor’s degree in computer science, or equivalent work experience;
					li Strong analytical and problem solving skills;
					li Genuine desire to learn new technologies and methods;
					li Experience coding with Javascript, HTML, and CSS;
					li Strong verbal and written communication skills.
				h3 Additional Skills Sought
				ul
					li Background in graphic design;
					li Background in database design and development;
					li Familiarity with Node.JS, Golang, or mobile development using JavaScript;
					li Familiarity with Redis, MongoDB or other NoSQL key-value database;
					li Familiarity with front-end JavaScript libraries or CSS frameworks, such as jQuery, Socket.io, React.js, Angular, or Bootstrap.
				p To apply, please submit your resume and a brief cover letter (optional) to the email address listed in the
					= " "
					a(href="#contact-us") contact section above
					| . Links to your portfolio or code repository are strongly encouraged.
				p Only Ohio residents will be considered. Please do not apply if you live out-of-state.
	script.
		export default {
			state: { classes: "hide" },
			onMounted() {
				this.hashChange = () => {
					if (window.location.hash.match(/^#job-postings/)) {
						this.update({ classes: "" })
					}
				}
				window.addEventListener("hashchange", this.hashChange)
				this.hashChange()
			},
			onUnmounted() {
				window.removeEventListener("hashchange", this.hashChange)
			},
		}
